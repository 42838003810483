<template lang="pug">
.vehicle-model-block
  .model-title
    h1 {{ title }}
  .model-desc
    span(v-html="description")
</template>

<script>
export default {
  name: 'VehicleModel',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
}
</script>